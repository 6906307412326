/* eslint-disable max-len */
const dashboardHelp = [
  {
    title: 'How to Change Selected indicator',
    steps: {
      First_Method: [
        'Click on the dropdown arrow next to the main indicator.',
        'Scroll down  to the appropriate program area where the desired indicator can be found.',
        'Click on the program dropdown.',
        'You can also type keywords of the desired indicator into the search field.',
        'When found, click on the desired indicator.',
      ],
      Second_Method: [
        'Go to the indicator search field at the top of the platform.',
        'Scroll down to the appropriate program area where the desired indicator can be found.',
        'Click on the program dropdown.',
        'You can also type keywords of the desired indicator into the search field.',
        'When found, click on the desired indicator.',
      ],
    },
    show: false,
  },
  {
    title: 'How to change related indicator',
    steps: [
      'Click on the dropdown arrow next to the related indicator you want to change.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to check for more information on the indicator',
    steps: [
      'Select the indicator you want to see more information on.',
      // 'On the table, click on the info icon <i data-v-862f69e2 class="fas fa icon fa-info-circle" style="color: #41918E; font-size: 15px;"></i> to the left of the indicator.',
      'On the table, click on the info icon 🛈 to the left of the indicator.',
      'Information on the indicator is given with respect to the data source, therefore, you can switch between the available data sources.',
      'You can also get more information on the indicator by going to the Information Page.',
    ],
    show: false,
  },
  {
    title: 'How to check information on the Data Source',
    steps: [
      // 'On the table, click on the info icon <i data-v-862f69e2 class="fas fa icon fa-info-circle" style="color: #41918E; font-size: 15px;"></i> by the data source you are interested in.',
      'On the table, click on the info icon 🛈 by the data source you are interested in.',
      'You can also get more information on the data sources by going to the information page.',
    ],
    show: false,
  },
  {
    title: 'How to change the dataset showing on all the charts',
    steps: ['Click on the desired dataset on the table.'],
    show: false,
  },
  {
    title: 'How to change the period showing on the Bar chart',
    steps: [
      'Click in the period filter found over the bar chart.',
      'There would be a drop down showing only periods with available data.',
      'Click on the desired period.',
    ],
    show: false,
  },
  {
    title:
      'How to change Location of the data showing on the indicator table and data trend (Line chart) chart',
    steps: [
      'Click on the Location filter found at the top right of the platform.',
      'Search and select the desired location.',
      'Data for the selected location will reflect on the Indicator table and line chart.',
    ],
    show: false,
  },
  {
    title: 'How to change data set showing on the Zonal chart',
    steps: [
      'Select the Dataset filter just above the zonal chart.',
      'Click on the dataset you want to see.',
      'The selected dataset will show on both the zonal chart and map.',
    ],
    show: false,
  },
  {
    title: 'How to change the period on the zonal chart',
    steps: ['Click on the period filter just above the zonal chart.', 'Select the desired period.'],
    show: false,
  },
  {
    title: 'How to compare multiple datasets for an indicator across the states',
    steps: [
      'Go to the multi-dataset comparison panel.',
      'Select the indicator you want to compare from the indicator filter just above the panel.',
      'Select the data sets you want to compare from the filter above the panel.',
      'You can select up to four datasets.',
    ],
    show: false,
  },
  {
    title: 'How to compare different indicators from the same data sets',
    steps: [
      'Open the “Compare Indicators” Panel.',
      'Select the data set you want to compare indicators from on the the filter just above the panel.',
      'Select the indicators you want to compare on the individual charts.',
      'You can change chart types: bar chart, column chart, a zonal chart and a states map for each chart.',
    ],
    show: false,
  },
  {
    title: 'How to download the data from a chart',
    steps: [
      'Click on the download button on each chart.',
      'Select either download as csv or download as xls.',
    ],
    show: false,
  },
  {
    title: 'How to download the chart as an image',
    steps: [
      'Click on the download button on each chart.',
      'Select the picture type you want to download the chart as: png, jpeg, pdf, vector image.',
    ],
    show: false,
  },
  {
    title: 'How to view information on the National target',
    steps: ['Click on the target line.'],
    show: false,
  },
  {
    title: 'How to check the confidence range.',
    steps: [
      'On the table, find the data set you want to check the confidence range for.',
      'If the confidence range is available, there would be a toggle under the data set on the table.',
      'Toggle the switch to view the confidence range.',
    ],
    show: false,
  },
];

export default dashboardHelp;
